import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class MenuSectionVisible extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="side-drawer-section">
        <h1>{this.props.title}</h1>
        {this.props.children}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

const MenuSection = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MenuSectionVisible)
);

export default MenuSection;
