import React, { Component } from 'react';
import { connect } from 'react-redux';
import { sidebarShow } from '../../State/actions';

import './DrawerToggleButton.css';

class DrawerToggleButtonVisible extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <button className="toggle-button" onClick={this.props.onButtonClick}>
        <span className="toggle-button__line" />
        <span className="toggle-button__line" />
        <span className="toggle-button__line" />
      </button>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    onButtonClick: e => {
      dispatch(sidebarShow(true));
    }
  };
};

const DrawerToggleButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(DrawerToggleButtonVisible);

export default DrawerToggleButton;
