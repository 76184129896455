import {
  OPEN_MODAL,
  CLOSE_MODAL,
  USER_LOGON,
  USER_LOGOUT,
  ECMA_EDITOR_START,
  ECMA_EDITOR_UPDATE,
  ECMA_EDITOR_THEME,
  SIDEBAR_SHOW
} from './actions.js';

const initialState = {
  user: {},
  modal: null,
  ecmaeditor: {
    code: '',
    theme: 'chrome'
  },
  sidebar: {
    show: false
  }
};

function user(state = {}, action) {
  switch (action.type) {
    case USER_LOGON:
      return action.payload;
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
}

function modal(state = null, action) {
  switch (action.type) {
    case OPEN_MODAL:
      return action.payload.id;
    case CLOSE_MODAL:
      return null;
    default:
      return state;
  }
}

function ecmaeditor(state = {}, action) {
  switch (action.type) {
    case ECMA_EDITOR_START:
      return Object.assign(state, { code: '' });
    case ECMA_EDITOR_UPDATE:
      return Object.assign(state, { code: action.payload.code });
    case ECMA_EDITOR_THEME:
      return Object.assign(state, { theme: action.payload.theme });
    default:
      return state;
  }
}

function sidebar(state = {}, action) {
  switch (action.type) {
    case SIDEBAR_SHOW:
      return Object.assign(state, { show: action.payload.show });
    default:
      return state;
  }
}

function app(state = initialState, action) {
  return {
    user: user(state.user, action),
    modal: modal(state.modal, action),
    ecmaeditor: ecmaeditor(state.ecmaeditor, action),
    sidebar: sidebar(state.sidebar, action)
  };
}

export default app;
