import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dropdown from '../../components/Dropdown/Dropdown';
import AceEditor from 'react-ace';
import 'brace/mode/javascript';
import 'brace/theme/ambiance';
import 'brace/theme/chaos';
import 'brace/theme/chrome';
import 'brace/theme/clouds';
import 'brace/theme/clouds_midnight';
import 'brace/theme/cobalt';
import 'brace/theme/crimson_editor';
import 'brace/theme/dawn';
import 'brace/theme/dracula';
import 'brace/theme/eclipse';
import 'brace/theme/gob';
import 'brace/theme/gruvbox';
import 'brace/theme/idle_fingers';
import 'brace/theme/iplastic';
import 'brace/theme/katzenmilch';
import 'brace/theme/kr_theme';
import 'brace/theme/kuroir';
import 'brace/theme/merbivore';
import 'brace/theme/merbivore_soft';
import 'brace/theme/mono_industrial';
import 'brace/theme/monokai';
import 'brace/theme/pastel_on_dark';
import 'brace/theme/solarized_light';
import 'brace/theme/solarized_dark';
import 'brace/theme/sqlserver';
import 'brace/theme/terminal';
import 'brace/theme/textmate';
import 'brace/theme/tomorrow';
import 'brace/theme/tomorrow_night_blue';
import 'brace/theme/tomorrow_night_bright';
import 'brace/theme/tomorrow_night_eighties';
import 'brace/theme/tomorrow_night';
import 'brace/theme/twilight';
import 'brace/theme/vibrant_ink';
import 'brace/theme/xcode';

import { ecmaEditorUpdate, ecmaEditorThemeChoice } from '../../State/actions';

class ECMAScriptVisible extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: props.code,
      theme: props.theme
    };
  }

  handleClick = e => {
    eval(this.props.code);
  };

  render() {
    const themeOptions = [
      { key: 'ambiance', text: 'Ambiance', value: 'ambiance' },
      { key: 'chaos', text: 'Chaos', value: 'chaos' },
      { key: 'chrome', text: 'Chrome', value: 'chrome' },
      { key: 'clouds', text: 'Clouds', value: 'clouds' },
      {
        key: 'clouds_midnight',
        text: 'Clouds Midnight',
        value: 'clouds_midnight'
      },
      { key: 'cobalt', text: 'Cobalt', value: 'cobalt' },
      {
        key: 'crimson_editor',
        text: 'Crimson Editor',
        value: 'crimson_editor'
      },
      { key: 'dawn', text: 'Dawn', value: 'dawn' },
      { key: 'dracula', text: 'Dracula', value: 'dracula' },
      { key: 'eclipse', text: 'Eclipse', value: 'eclipse' },
      { key: 'gob', text: 'Gob', value: 'gob' },
      { key: 'gruvbox', text: 'Gruvbox', value: 'gruvbox' },
      { key: 'idle_fingers', text: 'Idle Fingers', value: 'idle_fingers' },
      { key: 'iplastic', text: 'Iplastic', value: 'iplastic' },
      { key: 'katzenmilch', text: 'Katzenmilch', value: 'katzenmilch' },
      { key: 'kr_theme', text: 'KR Theme', value: 'kr_theme' },
      { key: 'kuroir', text: 'Kuroir', value: 'kuroir' },
      { key: 'merbivore', text: 'Merbivore', value: 'merbivore' },
      {
        key: 'merbivore_soft',
        text: 'Merbivore Soft',
        value: 'merbivore_soft'
      },
      {
        key: 'mono_industrial',
        text: 'Mono Industrial',
        value: 'mono_industrial'
      },
      { key: 'monokai', text: 'Monokai', value: 'monokai' },
      {
        key: 'pastel_on_dark',
        text: 'Pastel On Dark',
        value: 'pastel_on_dark'
      },
      {
        key: 'solarized_dark',
        text: 'Solarized Dark',
        value: 'solarized_dark'
      },
      {
        key: 'solarized_light',
        text: 'Solarized Light',
        value: 'solarized_light'
      },
      { key: 'sqlserver', text: 'SQL Server', value: 'sqlserver' },
      { key: 'terminal', text: 'Terminal', value: 'terminal' },
      { key: 'textmate', text: 'Textmate', value: 'textmate' },
      { key: 'tomorrow', text: 'Tomorrow', value: 'tomorrow' },
      {
        key: 'tomorrow_night_blue',
        text: 'Tomorrow Night Blue',
        value: 'tomorrow_night_blue'
      },
      {
        key: 'tomorrow_night_bright',
        text: 'Tomorrow Night Bright',
        value: 'tomorrow_night_bright'
      },
      {
        key: 'tomorrow_night_eighties',
        text: 'Tomorrow Night Eighties',
        value: 'tomorrow_night_eighties'
      },
      {
        key: 'tomorrow_night',
        text: 'Tomorrow Night',
        value: 'tomorrow_night'
      },
      { key: 'twilight', text: 'Twilight', value: 'twilight' },
      { key: 'vibrant_ink', text: 'Vibrant Ink', value: 'vibrant_ink' },
      { key: 'xcode', text: 'XCode', value: 'xcode' }
    ];

    return (
      <article>
        <Dropdown
          prompt="Select a theme..."
          items={themeOptions}
          value={this.props.theme}
          onChange={this.props.onThemeSelect}
        />
        <br />
        <AceEditor
          mode="javascript"
          theme={this.props.theme}
          name="ECMATestEditor"
          ref="editor"
          fontSize={15}
          value={this.props.code}
          onChange={this.props.onEditorUpdate}
          width="600px"
          height="600px"
          editorProps={{
            $blockScrolling: true
          }}
        />
        <br />
        <button onClick={this.handleClick}>Execute!</button>
      </article>
    );
  }
}

const mapStateToProps = state => {
  return {
    code: state.ecmaeditor.code,
    theme: state.ecmaeditor.theme
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onEditorUpdate: code => {
      dispatch(ecmaEditorUpdate(code));
      return code;
    },
    onThemeSelect: obj => {
      dispatch(ecmaEditorThemeChoice(obj.value));
    }
  };
};

const ECMAScript = connect(
  mapStateToProps,
  mapDispatchToProps
)(ECMAScriptVisible);

export default ECMAScript;
