import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import DefaultBody from './pages/DefaultBody';
import ECMAScript from './pages/Testers/ECMAScript';
import RegEx from './pages/Testers/RegEx';
import DevTkApi from './pages/Testers/DevTkApi';
import ASCII from './pages/Charts/ASCII';
import DateMath from './pages/Calculators/DateMath';
import BusinessCalc from './pages/Calculators/Business';
import RefLinks from './pages/Reference/Links';
import Publish from './pages/Blog/Publish';

class AppBodyVisible extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        style={{
          marginTop: '64px',
          padding: '0px 10px'
        }}
      >
        <Switch>
          <Route path="/tester/javascript" component={ECMAScript} />
          <Route path="/tester/regex" component={RegEx} />
          <Route path="/tester/devtkapi" component={DevTkApi} />
          <Route path="/chart/ascii" component={ASCII} />
          <Route path="/calculator/datemath" component={DateMath} />
          <Route path="/calculator/business" component={BusinessCalc} />
          <Route path="/reference/links" component={RefLinks} />
          <Route path="/blog/publish" component={Publish} />
          <Route component={DefaultBody} />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

const AppBody = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AppBodyVisible)
);

export default AppBody;
