import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import MenuSection from './MenuSection';
import SideDrawerLink from '../SideDrawer/SideDrawerLink';

class SectionBlogAdminVisible extends Component {
  render() {
    if (!this.props.showSection) {
      return null;
    }

    let publishLink = this.props.canContribute ? (
      <li>
        <SideDrawerLink to="/blog/publish">Publish</SideDrawerLink>
      </li>
    ) : null;

    return (
      <MenuSection title="Blog Admin">
        <ul>{publishLink}</ul>
      </MenuSection>
    );
  }
}

const mapStateToProps = state => {
  let showSection = false;
  let canContribute = false;
  if (
    'user' in state &&
    'profile' in state.user &&
    'groups' in state.user.profile &&
    state.user.profile.groups.indexOf('blog_contributor') > -1
  ) {
    canContribute = true;
  }

  showSection = canContribute;

  return { showSection, canContribute };
};

const mapDispatchToProps = dispatch => {
  return {};
};

const SectionBlogAdmin = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SectionBlogAdminVisible)
);

export default SectionBlogAdmin;
