import React, { Component } from 'react';

export default class ExternalLink extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <a
        href={this.props.href}
        target="_blank"
        rel="noopener noreferrer"
        title={this.props.description}
      >
        {this.props.description}
      </a>
    );
  }
}
