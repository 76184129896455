import React, { Component } from 'react';
import { verifyUser } from './AmazonCognito';
import { connect } from 'react-redux';
import {
  MODAL_ID_LOGON,
  MODAL_ID_USER_VERIFY,
  openModal
} from '../../State/actions';

class UserVerifyModalVisible extends Component {
  constructor(props) {
    super(props);
    let username = 'username' in props ? props.username : '';
    let verifycode = 'verifycode' in props ? props.verifycode : '';
    this.state = {
      username,
      verifycode
    };
  }

  clearForm = () => {
    this.setState({
      username: '',
      verifycode: ''
    });
  };

  handleUsernameChange = event => {
    this.setState({ username: event.target.value });
  };

  handleVerifyCodeChange = event => {
    this.setState({ verifycode: event.target.value });
  };

  handleSubmit = event => {
    var that = this;
    function cb(err, data) {
      if (err) {
        alert('ERROR: ' + JSON.stringify(err));
      } else {
        that.props.onVerified({
          username: s.username
        });
        that.clearForm();
      }
    }

    const s = this.state;
    verifyUser(s.username, s.verifycode, cb);
    event.preventDefault();
  };

  render() {
    let boxClass = 'dtk-modalbox'.concat(this.props.modalOpen ? '' : '-hidden');
    return (
      <div className={boxClass}>
        <div className="dtk-modalcontent">
          <div className="dtk-modaltitle">
            <span>Verify User Registration</span>
          </div>
          <div className="dtk-modalbody">
            <form className="dtk-modalform">
              <ul>
                <li>
                  <label>
                    <span>Username</span>
                  </label>
                  <input
                    id="ver_username_field"
                    name="username"
                    value={this.state.username}
                    onChange={this.handleUsernameChange}
                  />
                </li>
                <li>
                  <label>
                    <span>Verification Code</span>
                  </label>
                  <input
                    id="ver_code_field"
                    name="verification"
                    value={this.state.verifycode}
                    onChange={this.handleVerifyCodeChange}
                  />
                </li>
                <li>
                  <button
                    className="dtk-button-action"
                    onClick={this.handleSubmit}
                  >
                    <span>Verify</span>
                  </button>
                  <button
                    className="dtk-button-altaction"
                    onClick={this.handleRequestCode}
                    style={{ float: 'right' }}
                  >
                    Resend Code
                  </button>
                </li>
              </ul>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = appstate => {
  return {
    modalOpen: appstate.modal === MODAL_ID_USER_VERIFY
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onVerified: info => {
      dispatch(openModal(MODAL_ID_LOGON));
    }
  };
};

const UserVerifyModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserVerifyModalVisible);

export default UserVerifyModal;
