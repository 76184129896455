import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { arrayBufferToBase64 } from '../../lib/base64';
import Latch from '../../lib/Latch';
import { callApi } from '../../lib/ApiClient';

function readTextFile(f, cb) {
  let reader = new FileReader();
  reader.onload = function(e) {
    let text = reader.result;
    cb(null, {
      name: f.name,
      format: 'text',
      content: text
    });
  };

  reader.readAsText(f);
}

function readBinaryFile(f, cb) {
  let reader = new FileReader();
  reader.onload = function(e) {
    let body = arrayBufferToBase64(reader.result);
    let retval = {
      name: f.name,
      format: 'base64',
      content: body
    };
    cb(null, retval);
  };

  reader.readAsArrayBuffer(f);
}

function readFile(f, cb) {
  let lcname = f.name.toLowerCase();
  if (lcname.length > 5) {
    let check = lcname.substr(-5);
    if (check === '.html') {
      readTextFile(f, cb);
    } else {
      readBinaryFile(f, cb);
    }
  }
}

class PublishVisible extends Component {
  constructor(props) {
    super(props);
    let files = 'files' in props ? props.files : false;
    this.state = {
      files
    };
  }

  clearForm = () => {
    this.setState({
      files: false,
      blogtitle: ''
    });
  };

  handleBlogTitleChange = event => {
    this.setState({ blogtitle: event.target.value });
    event.preventDefault();
  };

  handleDirectoryChange = event => {
    let f = event.target.files;
    if (!f) {
      return;
    }

    this.setState({ files: f });
    event.preventDefault();
  };

  handleSubmit = event => {
    let that = this;
    let files = {};
    let latch = new Latch(this.state.files.length, function() {
      let blogObj = {
        title: that.state.blogtitle,
        content: files['post.html'].content,
        assets: []
      };

      let keys = Object.keys(files);
      for (let i = 0; i < keys.length; i++) {
        if (files[keys[i]].name === 'post.html') {
          continue;
        }
        blogObj.assets.push(files[keys[i]]);
      }
      callApi(
        'POST',
        'blog/publish',
        {
          obj: blogObj,
          session: that.props.session
        },
        function(err, resp) {
          if (err) {
            console.log(err);
          } else {
            console.log(JSON.stringify(resp));
          }
        }
      );
    });

    for (let i = 0; i < this.state.files.length; i++) {
      readFile(this.state.files[i], function(err, file) {
        if (err) {
          console.log(
            'Error reading file ' + this.state.files[i].name + ': ' + err
          );
          latch.trigger();
        } else {
          files[file.name] = file;
          latch.trigger();
        }
      });
    }

    event.preventDefault();
  };

  render() {
    if (!this.props.canContribute) {
      return (
        <article>
          <h1>Access Denied</h1>
        </article>
      );
    }

    return (
      <article>
        <form className="dtk-pageform">
          <label>
            <span>Blog Title</span>
          </label>
          <br />
          <input
            id="blogtitle_field"
            name="blogtitle"
            value={this.state.blogtitle}
            onChange={this.handleBlogTitleChange}
          />
          <label>
            <span>Enter Directory Name</span>
          </label>
          <input
            type="file"
            id="directory_field"
            name="directory"
            value={this.state.dirname}
            onChange={this.handleDirectoryChange}
            multiple
          />
          <button className="dtk-button-action" onClick={this.handleSubmit}>
            <span>Publish</span>
          </button>
        </form>
      </article>
    );
  }
}

const mapStateToProps = state => {
  let canContribute = false;
  if (
    'user' in state &&
    'profile' in state.user &&
    'groups' in state.user.profile &&
    state.user.profile.groups.indexOf('blog_contributor') > -1
  ) {
    canContribute = true;
  }

  let session = null;
  if ('user' in state && 'session' in state.user) {
    session = state.user.session;
  }

  return { canContribute, session };
};

const mapDispatchToProps = dispatch => {
  return {};
};

const Publish = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PublishVisible)
);

export default Publish;
