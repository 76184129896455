import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import MenuSection from './MenuSection';
import SideDrawerLink from '../SideDrawer/SideDrawerLink';

class SectionReferenceVisible extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <MenuSection title="Reference">
        <ul>
          <li>
            <SideDrawerLink to="/reference/links">Useful Links</SideDrawerLink>
          </li>
        </ul>
      </MenuSection>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

const SectionReference = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SectionReferenceVisible)
);

export default SectionReference;
