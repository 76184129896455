import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dropdown from '../../components/Dropdown/Dropdown';
import { callApi } from '../../lib/ApiClient';

class DevTkApiVisible extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verb: 'get',
      domain: '',
      objectid: '',
      reqbody: '',
      respcode: '',
      respbody: ''
    };
  }

  handleVerbSelect = obj => {
    this.setState({ verb: obj.value });
  };

  handleDomainChange = e => {
    this.setState({ domain: e.target.value });
    e.preventDefault();
  };

  handleObjectIdChange = e => {
    this.setState({ objectid: e.target.value });
    e.preventDefault();
  };

  handleReqBodyChange = e => {
    this.setState({ reqbody: e.target.value });
    e.preventDefault();
  };

  handleSubmit = e => {
    let that = this;
    let path = this.state.domain + '/' + this.state.objectid;
    let opts = { session: this.props.session };
    let reqObj = false;
    if (this.state.reqbody.length > 0) {
      try {
        reqObj = JSON.parse(this.state.reqbody);
      } catch (e) {}
    }
    if (reqObj) {
      opts.obj = reqObj;
    }
    callApi(this.state.verb, path, opts, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        that.setState({ respbody: JSON.stringify(resp) });
      }
    });
    e.preventDefault();
  };

  render() {
    const verbOptions = [
      { key: 'get', text: 'GET', value: 'GET' },
      { key: 'post', text: 'POST', value: 'POST' },
      { key: 'put', text: 'PUT', value: 'PUT' },
      { key: 'delete', text: 'DELETE', value: 'DELETE' }
    ];
    return (
      <article>
        <form>
          <ul>
            <li>
              <Dropdown
                prompt="HTTP Verb:"
                items={verbOptions}
                value={this.state.verb}
                onChange={this.handleVerbSelect}
              />
            </li>
            <li>
              <label>
                <span>Domain</span>
              </label>
              <input
                id="domain_field"
                name="domain"
                value={this.state.domain}
                onChange={this.handleDomainChange}
              />
            </li>
            <li>
              <label>
                <span>Object ID</span>
              </label>
              <input
                id="objectid_field"
                name="objectid"
                value={this.state.objectid}
                onChange={this.handleObjectIdChange}
              />
            </li>
            <li>
              <label>
                <span>Request Body</span>
              </label>
              <textarea
                value={this.state.reqbody}
                onChange={this.handleReqBodyChange}
              />
            </li>
            <li>
              <button onClick={this.handleSubmit}>Submit</button>
            </li>
            <li>
              <hr />
            </li>
            <li>
              <label>
                <span>Response Code</span>
              </label>
              <input readonly value={this.state.respcode} />
            </li>
            <li>
              <label>
                <span>Response Body</span>
              </label>
              <textarea readonly value={this.state.respbody} />
            </li>
          </ul>
        </form>
      </article>
    );
  }
}

const mapStateToProps = state => {
  let session = null;
  if ('user' in state && 'session' in state.user) {
    session = state.user.session;
  }

  return { session };
};

const mapDispatchToProps = dispatch => {
  return {};
};

const DevTkApi = connect(
  mapStateToProps,
  mapDispatchToProps
)(DevTkApiVisible);

export default DevTkApi;
