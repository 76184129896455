import React, { Component } from 'react';
import { connect } from 'react-redux';
import { sidebarShow } from '../../State/actions';
import SideDrawerBackdrop from './SideDrawerBackdrop';

import './SideDrawer.css';

class SideDrawerVisible extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: props.show
    };
  }

  render() {
    let backdrop;
    let drawerClasses = ['side-drawer'];
    if (this.props.show) {
      drawerClasses.push('open');
      backdrop = <SideDrawerBackdrop />;
    }
    return (
      <div>
        {backdrop}
        <nav className={drawerClasses.join(' ')}>{this.props.children}</nav>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    show: state.sidebar.show
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLinkClick: e => {
      dispatch(sidebarShow(false));
    }
  };
};

const SideDrawer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SideDrawerVisible);

export default SideDrawer;
