import React, { Component } from 'react';
import { connect } from 'react-redux';
import { sidebarShow } from '../../State/actions';

import './SideDrawerBackdrop.css';

class SideDrawerBackdropVisible extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        className="sidedrawer_backdrop"
        onClick={this.props.onBackdropClick}
      />
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    onBackdropClick: e => {
      dispatch(sidebarShow(false));
    }
  };
};

const SideDrawerBackdrop = connect(
  mapStateToProps,
  mapDispatchToProps
)(SideDrawerBackdropVisible);

export default SideDrawerBackdrop;
