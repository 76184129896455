import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { sidebarShow } from '../../State/actions';

class SideDrawerLinkVisible extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Link to={this.props.to} onClick={this.props.onLinkClick}>
        {this.props.children}
      </Link>
    );
  }
}

const mapStateToProps = state => {
  return {
    show: state.sidebar.show
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLinkClick: e => {
      dispatch(sidebarShow(false));
    }
  };
};

const SideDrawerLink = connect(
  mapStateToProps,
  mapDispatchToProps
)(SideDrawerLinkVisible);

export default SideDrawerLink;
