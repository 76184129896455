import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MODAL_ID_LOGON, openModal, userLogout } from '../../State/actions';

import './LoginButton.css';

class LoginButtonVisible extends Component {
  handleClick = () => {
    if (this.props.username === null) {
      this.props.onLogon();
    } else {
      this.props.onLogoff();
    }
  };

  render() {
    return (
      <button className="dtk-login-button" onClick={this.handleClick}>
        {this.buttonText()}
      </button>
    );
  }

  buttonText = () => {
    if (this.props.username === null) {
      return 'Log On';
    } else {
      return this.props.username;
    }
  };
}

const mapStateToProps = state => {
  let username = null;
  if (
    'user' in state &&
    'profile' in state.user &&
    'username' in state.user.profile
  ) {
    username = state.user.profile.username;
  }
  return { username };
};

const mapDispatchToProps = dispatch => {
  return {
    onLogon: () => {
      dispatch(openModal(MODAL_ID_LOGON));
    },
    onLogoff: () => {
      dispatch(userLogout());
    }
  };
};

const LoginButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginButtonVisible);

export default LoginButton;
