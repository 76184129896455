import React, { Component } from 'react';
import { connect } from 'react-redux';
import { callApi } from '../lib/ApiClient';

import './DefaultBody.css';

class DefaultBodyVisible extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <div id="blog" class="blog" />;
  }

  componentDidMount() {
    callApi('GET', 'blog/current', null, function(err, resp) {
      if (err) {
        console.log(err);
      } else {
        console.log(JSON.stringify(resp));
        var blogElement = document.getElementById('blog');
        blogElement.innerHTML = resp.result.content;
      }
    });
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

const DefaultBody = connect(
  mapStateToProps,
  mapDispatchToProps
)(DefaultBodyVisible);

export default DefaultBody;
