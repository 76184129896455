// Action Types
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export const MODAL_ID_LOGON = 'MI_LOGON';
export const MODAL_ID_REGISTER = 'MI_REGISTER';
export const MODAL_ID_USER_VERIFY = 'MI_USER_VERIFY';
export const MODAL_ID_PASSWORD_RESET = 'MI_PASSWORD_RESET';

export const USER_LOGON = 'USER_LOGON';
export const USER_LOGOUT = 'USER_LOGOUT';

export const ECMA_EDITOR_START = 'ECMA_EDITOR_START';
export const ECMA_EDITOR_UPDATE = 'ECMA_EDITOR_UPDATE';
export const ECMA_EDITOR_THEME = 'ECMA_EDITOR_THEME';

export const SIDEBAR_SHOW = 'SIDEBAR_SHOW';

export function openModal(id) {
  return {
    type: OPEN_MODAL,
    payload: { id }
  };
}

export function closeModal() {
  return {
    type: CLOSE_MODAL
  };
}

export function userLogon(userinfo) {
  return {
    type: USER_LOGON,
    payload: {
      session: userinfo.session,
      profile: userinfo.profile,
      groups: userinfo.groups
    }
  };
}

export function userLogout() {
  return {
    type: USER_LOGOUT
  };
}

export function ecmaEditorStart() {
  return {
    type: ECMA_EDITOR_START
  };
}
export function ecmaEditorUpdate(code) {
  return {
    type: ECMA_EDITOR_UPDATE,
    payload: {
      code: code
    }
  };
}

export function ecmaEditorThemeChoice(theme) {
  return {
    type: ECMA_EDITOR_THEME,
    payload: {
      theme: theme
    }
  };
}

export function sidebarShow(show) {
  return {
    type: SIDEBAR_SHOW,
    payload: {
      show: show
    }
  };
}
