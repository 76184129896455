import React, { Component } from 'react';
import { connect } from 'react-redux';
import { closeModal } from '../../State/actions';

import './ModalBackdrop.css';

class ModalBackdropVisible extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let divclass = 'modal_backdrop_'.concat(
      this.props.visible ? 'visible' : 'hidden'
    );
    return <div className={divclass} onClick={this.props.onBackdropClick} />;
  }
}

const mapStateToProps = state => {
  let visible = state.modal !== null;
  return { visible };
};

const mapDispatchToProps = dispatch => {
  return {
    onBackdropClick: e => {
      dispatch(closeModal());
    }
  };
};

const ModalBackdrop = connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalBackdropVisible);

export default ModalBackdrop;
