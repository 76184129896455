import React, { Component } from 'react';
import { connect } from 'react-redux';

class RegExVisible extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <article>
        <h1>RegEx NOT IMPLEMENTED</h1>
      </article>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

const RegEx = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegExVisible);

export default RegEx;
