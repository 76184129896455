import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import MenuSection from './MenuSection';
import SideDrawerLink from '../SideDrawer/SideDrawerLink';

class SectionCalculatorsVisible extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <MenuSection title="Calculators">
        <ul>
          <li>
            <SideDrawerLink to="/calculator/business">Business</SideDrawerLink>
          </li>
        </ul>
      </MenuSection>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

const SectionCalculators = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SectionCalculatorsVisible)
);

export default SectionCalculators;
