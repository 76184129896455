import React, { Component } from 'react';
import { connect } from 'react-redux';
import ExternalLink from './ExternalLink';

class RefLinksVisible extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <article>
        <nav>
          <h1>Standards</h1>
          <ul>
            <li>
              <ExternalLink
                href="https://html.spec.whatwg.org/dev/"
                description="HTML: The Living Standard"
              />
            </li>
            <li>
              <ExternalLink
                href="https://meyerweb.com/eric/tools/css/reset/"
                description="Eric Meyer's CSS Reset"
              />
            </li>
          </ul>
          <h1>Source Control</h1>
          <ul>
            <li>
              <ExternalLink
                href="https://sethrobertson.github.io/GitFixUm/fixup.html"
                description="Reverting Changes in Git"
              />
            </li>
          </ul>
        </nav>
      </article>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

const RefLinks = connect(
  mapStateToProps,
  mapDispatchToProps
)(RefLinksVisible);

export default RefLinks;
