import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class MenuHeaderVisible extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <div className="side-drawer-header">
          <span>DevTK.NET</span>
        </div>
        <hr />
        <br />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

const MenuHeader = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MenuHeaderVisible)
);

export default MenuHeader;
