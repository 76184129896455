import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails
} from 'amazon-cognito-identity-js';

const userPool = new CognitoUserPool({
  UserPoolId: 'us-west-2_k6eo0LnDE',
  ClientId: '4v6p04919es7rfc38lls16np6l'
});

function authenticateUser(username, password, cb) {
  function handleAuthenticated(result) {
    var accessToken = result.getAccessToken();
    console.log(JSON.stringify(accessToken));
    cb(null, result);
  }

  function handleError(err) {
    cb(err, null);
  }

  const authDetails = new AuthenticationDetails({
    Username: username,
    Password: password
  });

  const cognitoUser = new CognitoUser({
    Username: username,
    Pool: userPool
  });

  cognitoUser.authenticateUser(authDetails, {
    onSuccess: handleAuthenticated,
    onFailure: handleError
  });
}

function registerUser(username, email, password, cb) {
  function handleSignUpResponse(err, data) {
    if (err) {
      console.log('userPool signUp Error: ' + JSON.stringify(err));
    }
    cb(err, data);
  }

  const attrList = [];
  attrList.push(
    new CognitoUserAttribute({
      Name: 'email',
      Value: email
    })
  );

  userPool.signUp(username, password, attrList, null, handleSignUpResponse);
}

function verifyUser(username, confirmationCode, cb) {
  function handleVerificationResponse(err, data) {
    if (err) {
      console.log('confirmRegistration Error: ' + JSON.stringify(err));
    }
    cb(err, data);
  }

  const userData = {
    Username: username,
    Pool: userPool
  };

  const cognitoUser = new CognitoUser(userData);
  cognitoUser.confirmRegistration(
    confirmationCode,
    true,
    handleVerificationResponse
  );
}

export { authenticateUser, registerUser, verifyUser };
