import React, { Component } from 'react';

import './Dropdown.css';

const promptKey = 'dtk-select-prompt-key';
const promptVal = '__NOTSELECTED__';

class Dropdown extends Component {
  constructor(props) {
    /*
     * props.prompt = Prompt text when no item has been selected
     * props.items = []
     * props.onChange = function(value)
     * props.value = value of selected item
     */
    super(props);
    let stateItems = [
      {
        key: promptKey,
        text: props.prompt,
        value: promptVal
      }
    ].concat(props.items);

    this.state = {
      items: stateItems,
      showDropdown: false,
      selectedItem: props.items.find(function(item) {
        return item.value === props.value;
      })
    };
  }

  notifyChange = item => {
    if (this.props.onChange) {
      this.props.onChange(item);
    }
  };

  topClicked = event => {
    event.preventDefault();
    let show = this.state.showDropdown;
    this.setState({ showDropdown: !show });
  };

  itemClicked = event => {
    event.preventDefault();
    let newVal = event.target.getAttribute('value');
    let newItem = this.state.items.find(function(item) {
      return item.value === newVal;
    });
    this.setState({
      showDropdown: false,
      selectedItem: newItem
    });
    this.notifyChange(newItem);
  };

  render() {
    let that = this;
    let selectedItem = this.state.selectedItem
      ? this.state.selectedItem
      : this.state.items[0];
    let selectedText = selectedItem.text;
    let topClasses = 'dtk-select-selected';
    let itemsClasses = 'dtk-select-items';
    if (this.state.showDropdown) {
      topClasses = topClasses.concat(' select-arrow-active');
    } else {
      itemsClasses = itemsClasses.concat(' dtk-select-hide');
    }

    return (
      <div className="dtk-select">
        <div className={topClasses} onClick={this.topClicked}>
          {selectedText}
        </div>
        <div className={itemsClasses}>
          {this.props.items.map(function(item) {
            if (item.key === selectedItem.key) {
              return (
                <div
                  key={item.key}
                  value={item.value}
                  onClick={that.itemClicked}
                  className="dtk-same-as-selected"
                >
                  {item.text}
                </div>
              );
            } else {
              return (
                <div
                  key={item.key}
                  value={item.value}
                  onClick={that.itemClicked}
                >
                  {item.text}
                </div>
              );
            }
          })}
        </div>
      </div>
    );
  }
}

export default Dropdown;
