import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MenuHeader from './MenuHeader';
import SectionBlogAdmin from './SectionBlogAdmin';
import SectionTesters from './SectionTesters';
import SectionReference from './SectionReference';
import SectionCalculators from './SectionCalculators';
import SectionCharts from './SectionCharts';

class MenuVisible extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <MenuHeader />
        <SectionBlogAdmin />
        <SectionCalculators />
        <SectionCharts />
        <SectionTesters />
        <SectionReference />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

const Menu = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MenuVisible)
);

export default Menu;
