import React, { Component } from 'react';
import AppBody from './AppBody';
import ModalBackdrop from './appComponents/ModalBackdrop/ModalBackdrop';
import Toolbar from './appComponents/Toolbar/Toolbar';
import SideDrawer from './appComponents/SideDrawer/SideDrawer';
import LoginModal from './appComponents/Session/LoginModal';
import UserRegisterModal from './appComponents/Session/UserRegisterModal';
import UserVerifyModal from './appComponents/Session/UserVerifyModal';
import Menu from './appComponents/Menu/Menu';

class App extends Component {
  render() {
    return (
      <div style={{ height: '100%' }}>
        <ModalBackdrop />
        <LoginModal />
        <UserRegisterModal />
        <UserVerifyModal />
        <Toolbar />
        <SideDrawer>
          <Menu />
        </SideDrawer>
        <main>
          <AppBody />
        </main>
      </div>
    );
  }
}

export default App;
