import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import MenuSection from './MenuSection';
import SideDrawerLink from '../SideDrawer/SideDrawerLink';

class SectionTestersVisible extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let apiTestLink = this.props.showSiteDev ? (
      <li>
        <SideDrawerLink to="/tester/devtkapi">devtk.net API</SideDrawerLink>
      </li>
    ) : null;

    return (
      <MenuSection title="Testers">
        <ul>
          {apiTestLink}
          <li>
            <SideDrawerLink to="/tester/javascript">JavaScript</SideDrawerLink>
          </li>
        </ul>
      </MenuSection>
    );
  }
}

const mapStateToProps = state => {
  let showSiteDev = false;
  if (
    'user' in state &&
    'profile' in state.user &&
    'groups' in state.user.profile &&
    state.user.profile.groups.indexOf('site_dev') > -1
  ) {
    showSiteDev = true;
  }

  return { showSiteDev };
};

const mapDispatchToProps = dispatch => {
  return {};
};

const SectionTesters = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SectionTestersVisible)
);

export default SectionTesters;
