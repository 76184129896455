import { getStatusText } from 'http-status-codes';

const urlBase = 'https://api.devtk.net/';
const publicPrefix = 'public/';
const apiVersion = '1.0/';
const RS_DONE = 4;
const API_TIMEOUT = 3000;

function callApi(verb, path, opts, cb) {
  if (!opts) {
    opts = {};
  }
  const http = new XMLHttpRequest();

  function handleTimeout() {
    cb('Timeout');
  }

  function formatHttpError() {
    if (http.statusText) {
      return http.statusText;
    }

    let err = null;
    try {
      err = http.status + ' ' + getStatusText(http.status);
    } catch (e) {
      err = http.status + ' Unknown';
    }
    return err;
  }

  function handleResponse(e) {
    if (http.status !== 200) {
      let err = formatHttpError();
      console.log(verb + ' to ' + url + ': ' + err);
      cb(err);
    } else {
      let respObj = null;
      if (http.responseText) {
        let parsed = false;
        try {
          respObj = JSON.parse(http.responseText);
          parsed = true;
        } catch (e) {
          cb(e);
        }
        if (parsed) {
          cb(null, respObj);
        }
      } else {
        cb();
      }
    }
  }

  function handleReadyStateChange(e) {
    if (http.readyState !== RS_DONE) {
      return;
    }
    handleResponse(e);
  }

  let url = urlBase;
  if (!opts.session) {
    url = url.concat(publicPrefix);
  }
  url = url.concat(apiVersion, path);

  http.open(verb, url);

  let body = null;
  if (opts.obj) {
    body = JSON.stringify(opts.obj);
    http.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
  }

  if (opts.session) {
    http.setRequestHeader('Authorization', opts.session);
  }

  http.timeout = API_TIMEOUT;
  http.send(body);

  http.ontimeout = handleTimeout;
  http.onreadystatechange = handleReadyStateChange;
}

export { callApi };
