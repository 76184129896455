import React, { Component } from 'react';
import { connect } from 'react-redux';

class ASCIIVisible extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <article>
        <h1>ASCII</h1>
        <table>
          <tr>
            <th>DEC</th>
            <th>HEX</th>
            <th>HTML<br />Code</th>
            <th>HTML<br />Abbr</th>
            <th>Symbol</th>
            <th>Glyph</th>
            <th>Note</th>
          </tr>
          <tr>
            <td>0</td>
            <td>0x00</td>
            <td>&amp;#000;</td>
            <td></td>
            <td>NUL</td>
            <td></td>
            <td></td>
          </tr>
        </table>
      </article>
    );
  }
}
/*
 Decimal  Hex HTML  HTML  Symbol  Glyph  Note
              Code  Abbr
*/

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

const ASCII = connect(
  mapStateToProps,
  mapDispatchToProps
)(ASCIIVisible);

export default ASCII;
