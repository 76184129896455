import React, { Component } from 'react';
import { authenticateUser } from './AmazonCognito';
import { connect } from 'react-redux';
import {
  userLogon,
  openModal,
  closeModal,
  MODAL_ID_LOGON,
  MODAL_ID_REGISTER,
  MODAL_ID_PASSWORD_RESET
} from '../../State/actions';

class LoginModalVisible extends Component {
  constructor(props) {
    super(props);
    let username = 'username' in props ? props.username : '';
    let password = 'password' in props ? props.password : '';
    this.state = {
      username,
      password,
      isWrong: false
    };
  }

  clearForm = () => {
    this.setState({
      username: '',
      password: ''
    });
  };

  handleUsernameChange = event => {
    this.setState({ username: event.target.value });
  };

  handlePasswordChange = event => {
    this.setState({ password: event.target.value });
  };

  handleForgotPassword = event => {
    this.props.onPasswordResetRequest();
    event.preventDefault();
  };

  flagWrong = () => {
    let that = this;
    this.clearForm();
    this.usernameInput.focus();
    this.setState({ isWrong: true });
    setTimeout(function() {
      that.setState({ isWrong: false });
    }, 750);
  };

  handleSubmit = event => {
    var that = this;
    function cb(err, data) {
      if (err) {
        that.flagWrong();
      } else {
        let accessToken = data.getAccessToken();
        let loginInfo = {
          profile: {
            username: data.idToken.payload['cognito:username'],
            email: data.idToken.payload.email,
            groups: []
          },
          session: data.idToken.jwtToken
        };
        let cg = accessToken.payload['cognito:groups'];
        for (let i = 0; i < cg.length; i++) {
          loginInfo.profile.groups.push(cg[i]);
        }
        that.clearForm();
        that.props.onLoggedIn(loginInfo);
      }
    }

    const s = this.state;
    authenticateUser(s.username, s.password, cb);
    event.preventDefault();
  };

  handleRegister = event => {
    this.clearForm();
    this.props.onRegistrationRequired();
    event.preventDefault();
  };

  render() {
    let boxClass = 'dtk-modalbox'.concat(this.props.modalOpen ? '' : '-hidden');
    if (this.state.isWrong) {
      boxClass = boxClass.concat(' wrong');
    }
    return (
      <div className={boxClass}>
        <div className="dtk-modalcontent">
          <div className="dtk-modaltitle">
            <span>User Login</span>
          </div>
          <div className="dtk-modalbody">
            <form className="dtk-modalform">
              <ul>
                <li>
                  <label>
                    <span>Username</span>
                  </label>
                  <input
                    ref={input => {
                      this.usernameInput = input;
                    }}
                    id="username_field"
                    name="username"
                    value={this.state.username}
                    onChange={this.handleUsernameChange}
                  />
                </li>
                <li>
                  <label>
                    <span>Password</span>
                  </label>
                  <input
                    ref={input => {
                      this.passwordInput = input;
                    }}
                    id="password_field"
                    type="password"
                    name="password"
                    value={this.state.password}
                    onChange={this.handlePasswordChange}
                  />
                </li>
                <li>
                  <button
                    className="dtk-button-action"
                    onClick={this.handleSubmit}
                  >
                    <span>Login</span>
                  </button>
                  <button
                    className="dtk-button-altaction"
                    onClick={this.handleForgotPassword}
                  >
                    Forgot Password
                  </button>
                  <button
                    className="dtk-button-altaction"
                    onClick={this.handleRegister}
                    style={{ float: 'right' }}
                  >
                    Register New User
                  </button>
                </li>
              </ul>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    modalOpen: state.modal === MODAL_ID_LOGON
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLoggedIn: userinfo => {
      dispatch(userLogon(userinfo));
      dispatch(closeModal());
    },
    onPasswordResetRequest: () => {
      dispatch(openModal(MODAL_ID_PASSWORD_RESET));
    },
    onRegistrationRequired: () => {
      dispatch(openModal(MODAL_ID_REGISTER));
    },
    onCancel: () => {
      dispatch(closeModal());
    }
  };
};

const LoginModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginModalVisible);

export default LoginModal;
