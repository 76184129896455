import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import LoginButton from '../Session/LoginButton';

import DrawerToggleButton from '../SideDrawer/DrawerToggleButton';
import './Toolbar.css';

class ToolbarVisible extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <header className="toolbar">
        <nav className="toolbar__navigation">
          <DrawerToggleButton />
          <div className="toolbar__logo">
            <Link to="/">DevTK.NET</Link>
          </div>
          <div className="toolbar__spacer" />
          <div className="toolbar_navigation-items">
            <ul>
              <li>
                <LoginButton />
              </li>
            </ul>
          </div>
        </nav>
      </header>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

const Toolbar = connect(
  mapStateToProps,
  mapDispatchToProps
)(ToolbarVisible);

export default Toolbar;
