class Latch {
  constructor(count, cb) {
    this.maxCount = count;
    this.callback = cb;
    this.count = 0;
  }

  trigger() {
    this.count += 1;
    if (this.count >= this.maxCount) {
      this.callback();
    }
  }
}

export default Latch;
