import React, { Component } from 'react';
import { connect } from 'react-redux';

class BusinessCalcVisible extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <article>
        <h1>BusinessCalc NOT IMPLEMENTED</h1>
      </article>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

const BusinessCalc = connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessCalcVisible);

export default BusinessCalc;
