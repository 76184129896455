import React, { Component } from 'react';
import { registerUser } from './AmazonCognito';
import { connect } from 'react-redux';
import {
  MODAL_ID_LOGON,
  MODAL_ID_REGISTER,
  MODAL_ID_USER_VERIFY,
  openModal
} from '../../State/actions';

class UserRegisterModalVisible extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      email: '',
      password: ''
    };
  }

  clearForm = () => {
    this.setState({
      username: '',
      email: '',
      password: ''
    });
  };

  handleUsernameChange = event => {
    this.setState({ username: event.target.value });
  };

  handleEmailChange = event => {
    this.setState({ email: event.target.value });
  };

  handlePasswordChange = event => {
    this.setState({ password: event.target.value });
  };

  handleVerify = event => {
    this.clearForm();
    this.props.onVerify();
    event.preventDefault();
  };

  handleSubmit = event => {
    var that = this;
    function cb(err, data) {
      if (err) {
        alert('ERROR: ' + JSON.stringify(err));
      } else {
        that.props.onRegistered({
          needsVerification: true,
          username: s.username,
          email: s.email
        });
        that.clearForm();
      }
    }

    const s = this.state;
    registerUser(s.username, s.email, s.password, cb);
    event.preventDefault();
  };

  handleCancel = event => {
    this.props.onCancel();
    this.clearForm();
    event.preventDefault();
  };

  render() {
    let boxClass = 'dtk-modalbox'.concat(this.props.modalOpen ? '' : '-hidden');
    return (
      <div className={boxClass}>
        <div className="dtk-modalcontent">
          <div className="dtk-modaltitle">
            <span>Register New User</span>
          </div>
          <div className="dtk-modalbody">
            <form className="dtk-modalform">
              <ul>
                <li>
                  <label>
                    <span>Username</span>
                  </label>
                  <input
                    id="reg_username_field"
                    name="username"
                    value={this.state.username}
                    onChange={this.handleUsernameChange}
                  />
                </li>
                <li>
                  <label>
                    <span>email Address</span>
                  </label>
                  <input
                    id="reg_email_field"
                    type="email"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleEmailChange}
                  />
                </li>
                <li>
                  <label>
                    <span>Password</span>
                  </label>
                  <input
                    id="reg_password_field"
                    type="password"
                    name="password"
                    value={this.state.password}
                    onChange={this.handlePasswordChange}
                  />
                </li>
                <li>
                  <button
                    className="dtk-button-action"
                    onClick={this.handleSubmit}
                  >
                    <span>Register</span>
                  </button>
                  <button
                    className="dtk-button-altaction"
                    onClick={this.handleVerify}
                    style={{ float: 'right' }}
                  >
                    I Already Have a Code
                  </button>
                </li>
              </ul>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    modalOpen: state.modal === MODAL_ID_REGISTER
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onRegistered: reginfo => {
      dispatch(openModal(MODAL_ID_USER_VERIFY));
    },
    onVerify: reginfo => {
      dispatch(openModal(MODAL_ID_USER_VERIFY));
    },
    onCancel: () => {
      dispatch(openModal(MODAL_ID_LOGON));
    }
  };
};

const UserRegisterModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserRegisterModalVisible);

export default UserRegisterModal;
